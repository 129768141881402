import * as React from 'react'
import { graphql } from 'gatsby'

import Content from '../components/Content';
import buildMetaData from '../services/buildMetaData';
import buildOgpTags from '../services/buildOgpTags';
import Seo from '../components/Seo';
import addToSchema from '../services/addToSchema';

const HomepageTemplate = ({ data }) => {
  const page = data.page || {}

  return <Content doc={page} />
}

export const Head = ({ data, pageContext }) => {
  const page = data.page || {}

  const meta = buildMetaData(page)
  const ogp = buildOgpTags(page, pageContext)

  let schema = page.data?.schema_json?.text || ""

  // FAQ
  if (page.data?.faq?.length) {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": page.data.faq.map(({ question: faq }) => (
        {
          "@type": "Question",
          "name": faq.document.data.question.text.replace(/(\r\n|\n|\r)/gm, ""),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.document.data.answer.text.replace(/(\r\n|\n|\r)/gm, "")
          }
        }
      ))
    })
  }

  return <Seo schema={schema} ogp={ogp} meta={meta} breadcrumbs={pageContext.breadcrumbs} path={pageContext.url} />
}

export const query = graphql`
  query homepageQuery($lang: String) {
    page: prismicHomepage(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {           
        meta_title
        meta_description
        meta_keywords
        schema_json {
          text
        }
        faq {
          question {
            document {
              ... on PrismicFaq {
                id
                data {
                  answer {
                    text
                  }
                  question {
                    text
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicHomepageDataBodyHero {
            id
            slice_type
            primary {
              hero_title {
                richText
              }
              hero_subtitle {
                richText
              }
              hero_button_text
              hero_button_url
              hero_image {
                alt
                gatsbyImageData(
                  height: 341
                )
              }
            }
          }
          ... on PrismicHomepageDataBodyIntro {
            id
            slice_type
            primary {
              intro_headline {
                richText
              }
              intro_box_1_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              intro_box_2_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              intro_description {
                richText
              }
              intro_box_3_text {
                richText
              }
            }
          }

          ... on PrismicHomepageDataBodyServices {
            id
            slice_type
            primary {
              services_title {                
                richText
              }
              services_button_text
              services_url
            }
            items {
              service {
                uid
                id
                type
                lang
                document {
                  ... on PrismicService {
                    id
                    uid
                    type
                    lang
                    data {
                      title {
                        text
                      }
                      icon {
                        alt
                        gatsbyImageData(height: 50)
                      }
                    }
                  }
                }
              }
            }
          }





          ... on PrismicHomepageDataBodyCarousel {
            id
            slice_type
            items {
              slide_image {
                gatsbyImageData(width: 400)
                alt
              }
            }
            primary {
              content {
                text
                richText
              }
            }
          }




          ... on PrismicHomepageDataBodyAd {
            id
            slice_type
            items {
              ad_size
              ad {
                document {
                  ... on PrismicAd {
                    id
                    type
                    uid
                    data {
                      title {
                        text
                      }
                      subtitle
                      url
                      left_upper_text {
                        richText
                      }
                      left_lower_text {
                        richText
                      }
                      image {
                        alt
                        gatsbyImageData(height: 255)
                      }
                    }
                  }

                  ... on PrismicBanner {
                    id
                    type
                    uid
                    data {
                      type
                      title {
                        text
                      }
                      subtitle                      
                      button_text
                      url
                      image {
                        alt
                        gatsbyImageData
                      }                      
                    }
                  }
                }
              }
            }
          }




          ... on PrismicHomepageDataBodyAboutUs {
            id
            slice_type
            primary {
              about_us_title {
                richText
              }
              about_us_description {
                richText
              }
              about_us_upper_left_text
              about_us_upper_right_text
              about_us_lower_left_text
              about_us_lower_right_text
            }
          }





          ... on PrismicHomepageDataBodyArticle {
            id
            slice_type
            primary {
              articles_title {
                richText
              }
              articles_button_text
              articles_number_of_articles_to_display
              articles_url
            }
          }



          

        }  
      }
    }
  }
`

export default HomepageTemplate